import axios from 'axios';

export const fetchLocation = async () => {
  try {
    const response = await axios.get(`https://api.ipgeolocation.io/ipgeo?apiKey=${process.env.GATSBY_GEO_API_KEY} `);
    return response.data;
  } catch (error) {
    console.error('Error fetching location:', error);
    throw error;
  }
};
