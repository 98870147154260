// extracted by mini-css-extract-plugin
export var close = "video-popup-module--close--FYDtx";
export var email = "video-popup-module--email--MWD26";
export var form = "video-popup-module--form--RtyRN";
export var link = "video-popup-module--link--JHRKQ";
export var modal = "video-popup-module--modal--YVRrl";
export var modalContent = "video-popup-module--modalContent---9GyG";
export var popup_container = "video-popup-module--popup_container--UAXzP";
export var popup_content = "video-popup-module--popup_content--R8ECE";
export var semiBold = "video-popup-module--semiBold--QqFxI";
export var slideDown = "video-popup-module--slideDown--zQT0P";
export var submit_large = "video-popup-module--submit_large--6-vt4";
export var submit_small = "video-popup-module--submit_small--OLaaG";
export var text_container = "video-popup-module--text_container--763wa";
export var video = "video-popup-module--video--i0q9I";