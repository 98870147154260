import React, { useState, useEffect, useRef } from 'react';
import Modal from 'react-modal';
import * as styles from './video-popup.module.scss';
import video from '../../images/blog/Video_blog.mp4';

const modalStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.58)',
    zIndex: 9000,
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    overflow: 'visible',
    padding: '0',
    border: 'none',
    backgroundColor: 'transparent',
    width: '75%',
  },
  zIndex: 9000,
};

export default function VideoPopup({ modalOpen, closeModal }) {
  const modalCloseTimeout = 100;
  const [showModal, setShowModal] = useState(false);
  const [formErrors, setFormErrors] = useState({ email: 'Veuillez entrer un email valide' });
  const [formData, setFormData] = useState({
    email: '',
    tags: ['BLOG'],
  });

  useEffect(() => {
    // Parse the URL
    const params = new URLSearchParams(window.location.search);
    const utmMedium = params.get('utm_medium');

    // Check if utm_medium=popup is present
    if (utmMedium === 'popup_blog') {
      setFormData({ ...formData, tags: [...formData.tags, 'popup_blog'] });
    }
  }, []);

  const handleReserveClick = () => {
    localStorage.setItem('videoModal', 'true');
  };

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (!validateEmail(value)) {
      setFormErrors({ ...formErrors, email: 'Email non valide' });
    } else {
      setFormErrors({ ...formErrors, email: '' });
    }
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await fetch(process.env.GATSBY_BLOG_POPUP, {
        method: 'POST',
        mode: 'no-cors',
        body: JSON.stringify(formData),
      });
      closeModal();
      setShowModal(true);
      document.getElementById('myform').reset();
    } catch (error) {
      console.log(error);
    }
  };

  const [showForm, setShowForm] = useState(false);
  const videoRef = useRef(null);
  const videoElement = videoRef.current;

  useEffect(() => {
    if (!videoElement) return;

    const checkTime = () => {
      if (videoElement && videoElement.duration - videoElement.currentTime <= 10) {
        setShowForm(true);
        const popup_content = document.querySelector('.popup_content');
        const video = document.querySelector('.video');
        popup_content.style.backgroundColor = 'white';
        video.style.borderRadius = '20px 20px 0px 0px';

        videoElement.removeEventListener('timeupdate', checkTime);
      }
    };
    videoElement.addEventListener('timeupdate', checkTime);

    return () => {
      if (videoElement) {
        videoElement.removeEventListener('timeupdate', checkTime);
      }
    };
  }, [videoElement]);

  return (
    <>
      {showModal && (
        <div className={styles.modal}>
          <div className={styles.modalContent}>
            <h2>Email pris en compte</h2>
            <button onClick={() => setShowModal(false)}>Fermer</button>
          </div>
        </div>
      )}
      {!showModal && (
        <Modal
          isOpen={modalOpen}
          onRequestClose={closeModal}
          contentLabel="Modal"
          style={modalStyles}
          closeTimeoutMS={modalCloseTimeout}
        >
          {/** Close Button */}
          <button className={styles.close} onClick={closeModal}>
            <svg width="12" height="12" viewBox="0 0 15 15">
              <path
                d="M1.033 14.468l6.48-6.48 6.467 6.467.475-.475-6.467-6.466 6.48-6.48-.528-.529-6.48 6.48L1.02.546l-.475.475 6.44 6.44-6.48 6.48z"
                fill="#000"
                fillRule="nonzero"
              />
            </svg>
          </button>

          <div className={styles.popup_container}>
            <div className={`${styles.popup_content} popup_content`}>
              <video ref={videoRef} className={`${styles.video} video`} controls playsInline webkit-playsInline>
                <source src={video} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
              {showForm && (
                <div className={`${styles.text_container}`}>
                  <p>
                    Pour échanger avec l'une de nos <span className={styles.semiBold}>coachs fertilité</span>, veuillez{' '}
                    <span className={styles.semiBold}>prendre rendez-vous</span> en laissant votre{' '}
                    <span className={styles.semiBold}> adresse e-mail : </span>
                  </p>
                  <form onSubmit={handleSubmit} id="myform" className={styles.form}>
                    <div className={styles.email}>
                      <input type="email" placeholder="Votre email" name="email" required onChange={handleChange} />
                    </div>
                    {/* desktop */}
                    {!formErrors.email && (
                      <div className={styles.submit_large}>
                        <button
                          type="submit"
                          to={'/blog?utm_source=blog&utm_medium=popup_blog'}
                          onClick={() => {
                            handleReserveClick();
                            window.open('https://calendly.com/bemum/15min', '_blank');
                          }}
                          className={styles.link}
                        >
                          Je prends rendez-vous
                        </button>
                      </div>
                    )}
                    {formErrors.email && (
                      <div className={styles.submit_large} style={{ backgroundColor: '#e3e3e3' }}>
                        <p
                          style={{
                            color: 'grey',
                            cursor: 'not-allowed',
                          }}
                        >
                          Je prends rendez-vous
                        </p>
                      </div>
                    )}
                  </form>
                </div>
              )}
            </div>
          </div>
        </Modal>
      )}
    </>
  );
}
